import { css } from "styled-components"

export const backgroundStyles = css`
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  padding: 1px 6px;
  margin-right: 5px;
  border-radius: 7px;
  cursor: default;

  ${({ theme }) => theme.media.tablet} {
    border-radius: 7px;
  }

  ${({ theme }) => theme.media.phone} {
    border-radius: 6px;
  }
`

export const buttonIconsStyle = css`
  height: 1.1rem;
  padding-left: 0.6rem;

  ${({ theme }) => theme.media.tablet} {
    height: 1rem;
    padding-left: 0.5rem;
  }

  ${({ theme }) => theme.media.phone} {
    height: 0.9rem;
    padding-left: 0.4rem;
  }
`

export const scrollBarStyles = css`
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.interactiveBorder};
    box-shadow: inset 0 0 5px grey;
    border-radius: 9px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(64, 79, 174, 0.5);
  }
`

export const hoverEffect = css`
  border: 1px solid rgba(233, 233, 255, 0);
  box-shadow: 0 0 8px rgba(233, 233, 255, 0);
  transition: border 500ms ease-out, box-shadow 200ms ease-out;

  &:hover {
    border: 1px solid rgba(233, 233, 255, 0.9);
    box-shadow: 0 0 8px rgba(233, 233, 255, 0.9);
    transition: border 500ms ease-out, box-shadow 200ms ease-out;
  }
`

//Fonts:
export const respTextFont = css`
  font-size: 0.9rem;
  font-family: ${({ theme }) => theme.fonts.secondary};

  ${({ theme }) => theme.media.phone} {
    font-size: 0.8rem;
  }
`

export const respH1Font = css`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  letter-spacing: 0.08rem;
  font-size: 2.5rem;

  ${({ theme }) => theme.media.tablet} {
    font-size: 2.3rem;
  }

  ${({ theme }) => theme.media.phone} {
    font-size: 2.2rem;
  }
`

export const respH2Font = css`
  font-family: ${({ theme }) => theme.fonts.primaryLight};
  opacity: 0.8;
  letter-spacing: 0.08rem;
  font-size: 1.8rem;
  line-height: 2.3rem;

  ${({ theme }) => theme.media.tablet} {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  ${({ theme }) => theme.media.phone} {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
`

export const respH3Font = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.6rem;

  ${({ theme }) => theme.media.tablet} {
    font-size: 1.5rem;
  }

  ${({ theme }) => theme.media.phone} {
    font-size: 1.3rem;
  }
`

export const respH4Font = css`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 1.4rem;
  letter-spacing: 0.52px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 1.2rem;
  }

  ${({ theme }) => theme.media.phone} {
    font-size: 1.1rem;
  }
`
