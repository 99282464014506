import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"

import NavButton from "./HeaderComponents/NavButton"
import MainLogo from "../../utils/assets/MainLogo"
import TutorialButton from "./HeaderComponents/TutorialButton"
// import GithubButtons from "./GithubButtons"

const paths = [
  { name: "Demo", route: "/" },
  { name: "Use Cases", route: "/UseCases" },
  // { name: "API Docs", route: "/APIDocs" },
  { name: "Contact", route: "/Contact" },
]

const Navbar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 3.5vw;

  ${({ theme }) => theme.media.tablet} {
    align-items: center;
    flex-direction: column;
  }
`

const MenuWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`

const LogoContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
`
const StyledMainLogo = styled(MainLogo)`
  height: calc(4vw + 3rem);
  width: calc(8vw + 2.2rem);

  ${({ theme }) => theme.media.phone} {
    height: calc((4vw + 3rem) * 2);
    width: calc((8vw + 2.2rem) * 2);
  }
`

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
    align-items: center;
  }
`

const Header = ({ siteTitle }) => {
  const location = useLocation()

  return (
    <Navbar>
      <LogoContainer data-cy="logo" to={paths[0].route}>
        <StyledMainLogo />
      </LogoContainer>
      <MenuWrapper>
        <NavContainer data-cy="menu-navigation">
          {paths.map((path, index) => (
            <NavButton
              key={index}
              name={path.name}
              route={path.route}
              location={location.pathname}
            />
          ))}
        </NavContainer>
      </MenuWrapper>
      <ButtonsContainer>
        {location.pathname === paths[0].route && <TutorialButton />}
        {/* <GithubButtons /> */}
      </ButtonsContainer>
    </Navbar>
  )
}

export default Header
