import React from "react"
import styled from "styled-components"

//@ts-ignore
import ScalacLogo from "../../utils/assets/ScalacSmall.png"
//@ts-ignore
import clutch from "../../utils/assets/clutch.png"
//@ts-ignore
import forbes from "../../utils/assets/forbes.png"
//@ts-ignore
import techReviewer from "../../utils/assets/techReviewer.png"

const PromoPanelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  max-width: 80px;

  ${({ theme }) => theme.media.phone} {
    flex-direction: row;
    justify-content: space-around;
    max-width: 100%;
    padding-top: 0;
  }
`

const StyledA = styled.a`
  display: flex;
  justify-content: center;
`

const StyledScalacLogo = styled.img`
  width: 41px;
  height: 93px;
  margin-top: 60px;

  ${({ theme }) => theme.media.phone} {
    transform: rotate(90deg);
    margin-top: 0;
  }
`

const StyledLogo = styled.img`
  width: 41px;
  height: 41px;
  margin-top: 30px;
`

const PromoPanel = () => {
  return (
    <PromoPanelWrapper data-cy="promotion-panel-home-page">
      <StyledA href="https://scalac.io/" target="_blank">
        <StyledScalacLogo data-cy="scalac-logo-home-page" src={ScalacLogo} alt="Scalac" />
      </StyledA>
      <StyledA href="https://clutch.co/profile/scalac" target="_blank">
        <StyledLogo data-cy="clutch-logo-home-page" src={clutch} />
      </StyledA>
      <StyledA href="https://scalac.io/" target="_blank">
        <StyledLogo data-cy="forbes-logo-home-page" src={forbes} />
      </StyledA>
      <StyledA
        href="https://techreviewer.co/companies/scalac"
        target="_blank"
      >
        <StyledLogo data-cy="techreviewer-logo-home-page" src={techReviewer} />
      </StyledA>
    </PromoPanelWrapper>
  )
}

export default PromoPanel
