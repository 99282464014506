import React from "react"
import styled, { css } from "styled-components"

const primaryStyle = css`
  background: transparent
    linear-gradient(263deg, rgba(65, 80, 175, 1) 0%, rgba(58, 73, 167, 1) 100%)
    0% 0% no-repeat padding-box;
  border: 2px solid rgba(65, 80, 175, 1);
`

const secondaryStyle = css`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 1);
  opacity: 0.63;
`

const StyledButton = styled.button`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 1.8rem;
  padding: 0.9rem 1.8rem;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 1.1rem;
  cursor: ${props => !props.disabled && "pointer"};
  ${(props: any) => (props.secondary ? secondaryStyle : primaryStyle)};
  opacity: ${props => props.disabled && 0.6};

  ${({ theme }) => theme.media.tablet} {
    margin-top: 0.4rem;
    border-radius: 1.7rem;
    padding: 0.8rem 1.7rem;
    font-size: 1rem;
  }

  ${({ theme }) => theme.media.phone} {
    margin-top: 0.3rem;
    border-radius: 1.6rem;
    padding: 0.7rem 1.6rem;
    font-size: 0.9rem;
  }
`

const Button = props => <StyledButton {...props}>{props.children}</StyledButton>

export default Button
