import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

type StyledContainerTypes = {
  isActive: boolean
}

const StyledLink = styled(Link)``

const StyledButton = styled.button<StyledContainerTypes>`
  color: ${({ isActive, theme }) =>
    theme.colors[isActive ? "primary" : "primaryInactive"]};
  background: ${({ theme }) => theme.colors.background};
  text-align: left;
  font-family: "Gotham Pro Black";
  font-size: calc(0.8vw + 1rem);
  letter-spacing: 0.72px;
  margin: 0.6vw;
  text-decoration: none;
  border: none;
  cursor: pointer;
  outline: none;

  ${({ theme }) => theme.media.tablet} {
    font-size: calc(1.5vw + 0.5rem);
  }

  ${({ theme }) => theme.media.phone} {
    font-size: calc(1vw + 1rem);
  }
`

const NavButton = ({ name, route, location }) => (
  <StyledLink to={route}>
    <StyledButton isActive={route === location}>{name}</StyledButton>
  </StyledLink>
)

export default NavButton
