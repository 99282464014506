import React from "react"

const MainLogo = props => (
    <svg width={228} height={111} viewBox="0 0 228 111" {...props}>
      <g data-name="Group 141">
        <path
          data-name="Polygon 3"
          d="M15.692 13.623a9 9 0 0115.617 0l7.968 13.9A9 9 0 0131.468 41H15.532a9 9 0 01-7.808-13.476z"
          transform="translate(-103.033 -64) translate(187 64)"
          fill="#ff4a3c"
        />
        <path
          data-name="Polygon 5"
          d="M15.865 13.612a9 9 0 0115.614 0L39.614 27.8a9 9 0 01-7.807 13.478h-16.27A9 9 0 017.73 27.8z"
          transform="translate(-103.033 -64) rotate(-120 106.012 37.31)"
          fill="#3fe0e0"
        />
        <path
          data-name="Path 1"
          d="M14.406 7.58c4.956-9.327 18.163-8.9 23.709.764l13.047 22.738c5.519 9.618-.692 21.183-11.195 20.844l-24.705-.8C4.759 50.792-2.184 38.8 2.747 29.521z"
          transform="translate(-103.033 -64) rotate(120 72.612 107.613)"
          fill="#ffcd43"
        />
        <text
          data-name="RO LF."
          transform="translate(-103.033 -64) translate(234 74)"
          fill="#fff"
          fontSize={40}
          fontFamily="GothamPro-Light, Gotham Pro"
          fontWeight={300}
        >
        <tspan x={0} y={29} style={{ textDecoration: "none" }}>
          {"RO"}
        </tspan>
        <tspan x={0} y={59} style={{ textDecoration: "none" }}>
          {"LF."}
        </tspan>
        </text>
      </g>
    </svg>
)

export default MainLogo
