import { DefaultTheme } from "styled-components"

import "styled-components"

declare module "styled-components" {
  export interface DefaultTheme {
    media: {
      tablet: string
      phone: string
    }
    colors: {
      primary: string
      primaryInactive: string
      secondary: string
      positive: string
      neutral: string
      negative: string
      background: string
      interactiveBorder: string
    }
    fonts: {
      primary: string
      primaryLight: string
      primaryBold: string
      secondary: string
      secondaryLight: string
      secondaryBold: string
    }
  }
}

const mediaTablet = "@media (max-width: 1024px)"
const mediaPhone = "@media (max-width: 728px)"

export const theme: DefaultTheme = {
  media: {
    tablet: mediaTablet,
    phone: mediaPhone,
  },
  colors: {
    primary: "rgba(255, 255, 255, 1)",
    primaryInactive: "rgba(255, 255, 255, 0.6)",
    secondary: "rgba(64, 79, 174, 1)",
    positive: "rgba(63, 224, 224, 1)",
    neutral: "rgba(213, 213, 213, 1)",
    negative: "rgba(255, 74, 60, 1)",
    background: "rgba(9, 8, 8, 1)",
    interactiveBorder: "rgba(64, 79, 174, 1)"
  },
  fonts: {
    primary: "Gotham Pro",
    primaryLight: "Gotham Pro Light",
    primaryBold: "Gotham Pro Black",
    secondary: "JetBrainsMono Medium",
    secondaryLight: "JetBrainsMono Regular",
    secondaryBold: "JetBrainsMono Bold",
  },
}
