import React from "react"
import styled from "styled-components"

import { useAppDispatch } from "../../../context/context"
import Button from "../../shared/Button"

const StyledTutorialButton = styled(Button)`
  transform: scale(0.6);
  margin: 0;
`

const TutorialButton = () => {
  const dispatch = useAppDispatch()

  const handleYes = () => {
    dispatch({ type: "HIDE_TUTORIAL_AGREEMENT", payload: false })
    dispatch({ type: "TOGGLE_TUTORIAL", payload: true })
    dispatch({ type: "TOGGLE_INDEX_TUTORIAL", payload: true })
    dispatch({ type: "TOGGLE_EDIT_MODAL_TUTORIAL", payload: true })
    dispatch({ type: "TOGGLE_INSPECT_MODAL_TUTORIAL", payload: true })
  }

  return (
    <StyledTutorialButton data-cy="tutorial-button-home-page" secondary onClick={handleYes}>
      Tutorial
    </StyledTutorialButton>
  )
}

export default TutorialButton
