import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Header from "./header/Header"
import { theme } from "../utils/theme"
import { GlobalStyle } from "../utils/globalStyles"
import "../utils/fonts/gotham-pro/style.css"
import "../utils/fonts/jet-brains/style.css"
import PromoPanel from "./PromoPanel/PromoPanel"
import { respTextFont } from "../utils/sharedStyles"

const AppWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const StyledContentContainer = styled.div`
display: flex;
flex-grow: 1;
justify-content: space-between;

${({ theme }) => theme.media.phone} {
  flex-direction: column;
}
`

const StyledMain = styled.main`
  position: relative;
  display: flex;
  flex-grow: 1;
  margin: 0 2vw;

  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`

const StyledFooter = styled.footer`
  ${respTextFont}
  text-justify: center;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondaryLight};
  padding-bottom: 2rem;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  opacity: 0.66;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <Header siteTitle={data.site.siteMetadata.title} />
        <StyledMain>
          <StyledContentContainer>
            {children}
          </StyledContentContainer>
          <PromoPanel />
        </StyledMain>
        <StyledFooter>
          Scalac {new Date().getFullYear()} | COPYRIGHT ©
          {new Date().getFullYear()} ALL RIGHTS RESERVED
        </StyledFooter>
        <GlobalStyle theme={theme} />
      </AppWrapper>
    </ThemeProvider>
  )
}

export default Layout
